import React from "react";
import '../../assets/styles/index.scss';
import '../../assets/styles/themes/cerveja-me.scss';

const CervejaMe = () => {
  return <div>

    <div className="App cerveja-me">
      <header>
        <span>
          <a className="btn-back" href="./"><svg width="95" height="8" viewBox="0 0 95 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM95 3.5H1V4.5H95V3.5Z"  />
          </svg>
          </a>
          <strong>Delivery App Interface</strong>
        </span>
        {/* <ul className="menu-languages">
          <li className="active">EN</li>
          <li>ES</li>
          <li>PT</li>
        </ul> */}
        <span>
          <a className="link" href="#contact">Contact</a>
        </span>
      </header>

      <section className="cover">
        <span className="cover-name">Cerveja.me Delivery</span>
      </section>

      <section className="cover-img">
        <img src={require('../../img/cerveja-me-mockup.jpg')} />
      </section>

      <div className="container">
        <div className="col1">
          <h1 class="casepage-title">
            <span className="casepage-title_hat">Interface<span className="dash"></span>Founder, Designer</span>
            <span className="casepage-title_postname">Delivery App<br></br>Interface</span>
          </h1>
        </div>

        <div className="col2 casepage-client">
          <span className="casepage-partnership">for Cerveja.me | São Paulo, Brazil</span>
        </div>
      </div>

      {/* <section className="process">
        <div className="process_step">
          <h3 className="process_step_title">1. <br/>Insights</h3>
          <p>a brazilian designer, who belives that design is for everyone, but not in the cliches ways. a brazilian designer.</p>
        </div>
        <div className="process_step">
          <h3 className="process_step_title">2. <br/>Ideation</h3>
          <p>a brazilian designer, who belives that design is for everyone, but not in the cliches ways. a brazilian designer.</p>
        </div>
        <div className="process_step">
          <h3 className="process_step_title">3. <br/>Testing</h3>
          <p>a brazilian designer, who belives that design is for everyone, but not in the cliches ways. a brazilian designer.</p>
        </div>
        <div className="process_step">
          <h3 className="process_step_title">4. <br/>Refining</h3>
          <p>a brazilian designer, who belives that design is for everyone, but not in the cliches ways. a brazilian designer.</p>
        </div>
      </section> */}

      <div className="container">
        <div className="col1">
        </div>

        <div className="col2">
          <p className="casepage-paragraphy">Cerveja.me was my second adventure as entrepreneur. A simple beer delivery service focused in fast delivery and cold beer.</p>
          <p className="casepage-paragraphy">The app and service came to five cities: Campo Grande, Dourados, Ponta Grossa, Águas Claras e Brasília and on 2018 it got your first investiment from <a target="_blank" href="https://cotidiano.com.br/"> Cotidiano Aceleradora.</a></p>
          <h2 class="casepage-deliverables">
            <span className="casepage-deliverables_hat">Fast</span>
            <span className="casepage-deliverables_text">1st version launched <br></br> in one month</span>
          </h2>
          <h2 class="casepage-deliverables">
            <span className="casepage-deliverables_hat">Growth</span>
            <span className="casepage-deliverables_text">Five cities in Brazil</span>
          </h2>
          <h2 class="casepage-deliverables">
            <span className="casepage-deliverables_hat">Investment</span>
            <span className="casepage-deliverables_text">Seed round money</span>
          </h2>
        </div>
      </div>

      <div className="case-gallery">
        <div class="case-gallery_col1">
            <div className="mockup">
            <video src={require('../../img/prototype-cerveja-me-1.mp4')} width="750" height="500" autoPlay loop muted/>
            <img src={require('../../img/iphone.png')} />
          </div>
        </div>
        <div class="case-gallery_col2">
        <figure className="case-gallery_photo">
            <img src={require('../../img/cerveja-me-2.jpg')} />
          </figure>
          <figure className="case-gallery_photo">
            <img src={require('../../img/cerveja-me-3.jpg')} />
          </figure>
        </div>
      </div>

      <section className="colaborators container">
        <div class="col1"><strong>My role</strong> <br></br>Product Designer</div>
        <div class="col2">
          <ul>
            <strong>Also in this project</strong>
            <li>Jeferson Guardezi</li>
            <li>Cotidiano Aceleradora</li>
          </ul>
          <ul>
            <strong>Special Thanks to</strong>
            <li>Pedro Naif</li>
          </ul>
        </div>
      </section>

      <section className="articles">
        <a href="./welcome-to-favela" className="article">
          <figure>
            <img src={require('../../img/wtof-1.png')} />
          </figure>
          <div className="article-title">
            <span className="article-title_hat">Film Director</span>
            <span className="article-title_postname">Mini–Doc: <br></br>Welcome  to <br></br>Favela</span>
          </div>
          <span className="link-arrow">
            <svg width="231" height="8" viewBox="0 0 231 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M227.172 0.464318L230.354 3.6463C230.549 3.84156 230.549 4.15814 230.354 4.3534L227.172 7.53539C226.976 7.73065 226.66 7.73065 226.464 7.53539C226.269 7.34012 226.269 7.02354 226.464 6.82828L228.793 4.49985H0V3.49985H228.793L226.464 1.17142C226.269 0.976162 226.269 0.65958 226.464 0.464318C226.66 0.269056 226.976 0.269056 227.172 0.464318Z"  />
            </svg>
          </span>
        </a>
      </section>

      <footer id="contact">
        <span className="footer-heading">don't be shy, contact me.</span>
        <span className="footer-links">
          <a target="_blank" className="link" href="mailto:glauberlaender@gmail.com">Mail</a>
          <a target="_blank" className="link" href="tel:+5511941870613">Phone</a>
          <a target="_blank" className="link" href="http://linkedin.com/in/glauberlaender">Linkedin</a>
          <a target="_blank" className="link" href="http://twitter.com/glauberlaender">Twitter</a>
          <a target="_blank" className="link" href="http://instagram.com/glauberlaender">Instagram</a>
          <a target="_blank" className="link" href="http://savee.it/g_laender">Savee</a>
          <a target="_blank" className="link" href="http://pinterest.com/glauberlaender">Pinterest</a>
        </span>

        <p>by the way, this website was <a target="_blank" className="link" href="https://github.com/glauberlaender/laender.me/tree/master/laender-me">coded by me</a>.</p>
      </footer>

    </div>

  </div>;
};

export default CervejaMe;

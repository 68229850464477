import React from "react";
import '../../assets/styles/index.scss';
import '../../assets/styles/themes/youse.scss';

const WelcomeToFavela = () => {
  return <div>
    
    <div className="App youse">
    <header>
        <span>
        <a className="btn-back" href="./"><svg width="95" height="8" viewBox="0 0 95 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM95 3.5H1V4.5H95V3.5Z" />
          </svg>
          </a>
          <strong>Mini–doc: Welcome to Favela</strong>
        </span>
        {/* <ul className="menu-languages">
          <li className="active">EN</li>
          <li>ES</li>
          <li>PT</li>
        </ul> */}
        <span>
          <a className="link" href="#contact">Contact</a>
        </span>
      </header>

      <section className="cover">
        <span className="cover-name">Welcome to Favela</span>
      </section>

      <section className="cover-img player">
        <iframe src="https://player.vimeo.com/video/492086212?autoplay=1&muted=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
      </section>

      <div className="container">
        <div className="col6">
          <h1 class="casepage-title">
            <span className="casepage-title_hat">Mini–doc <span className="dash"></span> Film Director</span>
            <span className="casepage-title_postname">Welcome to Favela</span>
          </h1>
        </div>

        <div className="col2 casepage-client">
          <span className="casepage-partnership">by <a target="_blank" href="http://meiuca.design">Meiuca</a> for <svg width="49" height="14" viewBox="0 0 49 7" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24.827 10.2436C22.1796 10.2436 20.6166 8.5824 20.6166 6.22384V1.03548H23.0439V6.29516C23.0439 6.80138 23.2274 8.00759 24.827 8.00759C25.8938 8.00759 26.6658 7.2914 26.6658 6.29516V1.03548H29.0944V6.22515C29.0917 8.51512 27.4011 10.2436 24.827 10.2436ZM34.3526 10.2436C31.9444 10.2436 30.1613 9.03744 29.8677 7.09888H32.3317C32.6634 7.86757 33.6378 8.14756 34.354 8.14756C35.3285 8.14756 36.0638 7.86757 36.0638 7.34389C36.0638 6.83772 35.7321 6.6277 34.0781 6.38269C32.3861 6.13768 30.126 5.70147 30.126 3.69023C30.126 2.27404 31.432 0.807983 34.1705 0.807983C36.3395 0.807983 37.9568 1.92667 38.2694 3.62158H35.7497C35.603 3.20158 35.0689 2.87041 34.1678 2.87041C33.0466 2.87041 32.5858 3.25544 32.5858 3.5866C32.5858 4.05911 33.2844 4.21527 34.4614 4.39027C36.8153 4.75779 38.5061 5.24649 38.5061 7.20387C38.5086 8.98763 36.7798 10.2436 34.3526 10.2436ZM43.9735 2.83538C45.1313 2.83538 46.0514 3.49908 46.3452 4.54912H41.6387C41.9878 3.49908 42.8699 2.83538 43.9735 2.83538ZM45.9769 7.16889C45.5716 7.88509 44.7807 8.21757 43.972 8.21757C42.832 8.21757 41.9119 7.53641 41.6007 6.43518H48.752C49.1011 2.87041 46.7841 0.807983 43.9912 0.807983C41.3246 0.807983 39.1378 2.94042 39.1378 5.54399C39.1378 8.14756 41.3261 10.245 43.9912 10.245C45.9167 10.245 47.3847 9.41437 48.1578 8.10721L45.9769 7.16889ZM7.02058 10.2261H4.65311V7.09483L0.505249 1.03548H3.15678L5.82735 4.78472L8.51557 1.03548H11.1684L7.02058 7.09483V10.2261Z" />
<path d="M17.3698 5.56151C17.3698 4.00525 16.322 2.92154 15.0349 2.92154C13.7479 2.92154 12.7 4.00525 12.7 5.56151C12.7 7.08274 13.7479 8.13142 15.0349 8.13142C16.3206 8.1301 17.3698 7.08137 17.3698 5.56151ZM19.8134 5.54399C19.8134 8.23645 17.662 10.245 15.0336 10.245C12.4051 10.245 10.2727 8.23645 10.2727 5.54399C10.2727 2.85153 12.4051 0.807983 15.0336 0.807983C17.662 0.807983 19.8134 2.8529 19.8134 5.54399ZM19.8079 13.0048C19.8079 11.8443 18.8648 11.1645 17.7 11.1645H12.3861C11.2214 11.1645 10.2782 11.8443 10.2782 13.0048H19.8079Z" />
</svg> | São Paulo, Brazil</span>
        </div>
      </div>

      <section className="process">
        <div className="process_step">
          <h3 className="process_step_title"><span className="process_step_number">1</span>Assumpsions</h3>
          <p>We define certainties to be broken down later.</p>
        </div>
        <div className="process_step">
          <h3 className="process_step_title"><span className="process_step_number">2</span>Immersion</h3>
          <p>All the group came to favela and talked to people.</p>
        </div>
        <div className="process_step">
          <h3 className="process_step_title"><span className="process_step_number">3</span>Prototyping</h3>
          <p>Using recycle objects, we prototyped six new solutions.</p>
        </div>
        <div className="process_step">
          <h3 className="process_step_title"><span className="process_step_number">4</span>Testing</h3>
          <p>The solution's prototypes was tested by invited users.</p>
        </div>
      </section>

      <div className="container">
        <div className="col1">
     
        </div>

        <div className="col2">
        <p className="casepage-paragraphy">I’m sure it is the most excited project I have ever participated, 
          for two reasons: to use design methods to create solutions for the Brazilian favelas and to return to movies universe, 
          as a film director, another passion I have in life.</p>

          <p className="casepage-paragraphy">We start this project asking ourselves "How can the brands approach the favela?". So the answer was to brought 
            Youse to Jardim São Luiz, a brazilian favela in partnership with <a target="_blank" href="http://www.viela.org.br/">Projeto Viela</a>. </p>
            
          <p className="casepage-paragraphy">They walked trough the streets and talked to people about their problems,
            then they return to the office to ideate some solutions to be tested later by some real potential users.</p>
          <h2 class="casepage-deliverables">
            <span className="casepage-deliverables_hat">Method</span>
            <span className="casepage-deliverables_text">Three days <br></br>Design Sprint</span>
          </h2>
          <h2 class="casepage-deliverables">
            <span className="casepage-deliverables_hat">Challenge</span>
            <span className="casepage-deliverables_text">Six simultaneous <br></br>tables & subjects</span>
          </h2>
          <h2 class="casepage-deliverables">
            <span className="casepage-deliverables_hat">Outcome</span>
            <span className="casepage-deliverables_text">Six services prototypes tested by real users</span>
          </h2>
        </div>
      </div>

       <div className="case-gallery">
        <div class="case-gallery_col1">
          <figure className="case-gallery_photo">
            <img src={require('../../img/wtof-1.png')} />
          </figure>
        </div>
        <div class="case-gallery_col2">
        <figure className="case-gallery_photo">
            <img src={require('../../img/wtof-2.JPG')} />
          </figure>
          <figure className="case-gallery_photo">
            <img src={require('../../img/wtof-3.png')} />
          </figure>
        </div>
      </div> 

      <section className="colaborators container">
        <div class="col1">
          <span><strong>My role </strong><br></br>Design Sprint Instructor, Film Director</span>
        </div>
        
        <div class="col2">
            <ul>
                <strong>Also in this project</strong>
                <li>Paula Neves</li>
                <li>Ivan Pestana</li>
                <li>Marcelle Aleixo</li>
                <li>Anderson Gomes [Youse]</li>
                <li>Alga Leal [Youse]</li>
                <li>Anderson Buiú [Projeto Viela]</li>
                <li>+ 50...</li>
            </ul>

            <ul>
                <strong>Film</strong>
                <li>Glauber Laender</li>
                <li>Thiago Hassu</li>
                <li>Paula Neves</li>
                <li>Matheus Ferreira</li>
                <li>Thiago Souto</li>
                <li>Roney Lucio</li>
            </ul>

            <ul>
            <strong>Pics</strong>
                <li>Matheus Ferreira</li>
            </ul>
        </div>
      </section>

      <section className="articles">
      <a href="./soma-design-system" className="article">
          <figure>
            <img src={require('../../img/soma-mockup.jpg')} />
          </figure>
          <div className="article-title">
            <span className="article-title_hat">DesignOps</span>
            <span className="article-title_postname">Soma Design <br></br>System by XP Inc.</span>
          </div>
          <span className="link-arrow">
            <svg width="231" height="8" viewBox="0 0 231 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M227.172 0.464318L230.354 3.6463C230.549 3.84156 230.549 4.15814 230.354 4.3534L227.172 7.53539C226.976 7.73065 226.66 7.73065 226.464 7.53539C226.269 7.34012 226.269 7.02354 226.464 6.82828L228.793 4.49985H0V3.49985H228.793L226.464 1.17142C226.269 0.976162 226.269 0.65958 226.464 0.464318C226.66 0.269056 226.976 0.269056 227.172 0.464318Z"  />
            </svg>
          </span>
        </a>
      </section>

      <footer id="contact">
        <span className="footer-heading">don't be shy, contact me.</span>
        <span className="footer-links">
          <a target="_blank" className="link" href="mailto:glauberlaender@gmail.com">Mail</a>
          <a target="_blank" className="link" href="tel:+5511941870613">Phone</a>
          <a target="_blank" className="link" href="http://linkedin.com/in/glauberlaender">Linkedin</a>
          <a target="_blank" className="link" href="http://twitter.com/glauberlaender">Twitter</a>
          <a target="_blank" className="link" href="http://instagram.com/glauberlaender">Instagram</a>
          <a target="_blank" className="link" href="http://savee.it/g_laender">Savee</a>
          <a target="_blank" className="link" href="http://pinterest.com/glauberlaender">Pinterest</a>
        </span>

        <p>by the way, this website was <a target="_blank" className="link" href="https://github.com/glauberlaender/laender.me/tree/master/laender-me">coded by me</a>.</p>
      </footer>

    </div>

  </div>;
};

export default WelcomeToFavela;

import React from "react";
import '../../assets/styles/index.scss';
import '../../assets/styles/themes/enfase.scss';

const EnfaseInterface = () => {
  return <div>
    
    <div className="App enfase">
      <header>
        <span>
        <a className="btn-back" href="./"><svg width="95" height="8" viewBox="0 0 95 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM95 3.5H1V4.5H95V3.5Z"  />
          </svg>
          </a>
          <strong>Study Planner Dashboard</strong>
        </span>
        {/* <ul className="menu-languages">
          <li className="active">EN</li>
          <li>ES</li>
          <li>PT</li>
        </ul> */}
        <span>
        <a className="link" href="#contact">Contact</a>
        </span>
      </header>

      <section className="cover">
        <span className="cover-name">Study Dashboard</span>
      </section>

      <section className="cover-img">
        <img src={require('../../img/enfase-full.jpg')} />
      </section>

      <div className="container">
        <div className="col6">
          <h1 class="casepage-title">
            <span className="casepage-title_hat">Design Sprint <span className="dash"></span> Visual Designer</span>
            <span className="casepage-title_postname">Study Planner Dashboard</span>
          </h1>
        </div>

        <div className="col2 casepage-client">
          <span className="casepage-partnership">for Curso Ênfase | Rio de Janeiro, Brazil</span>
        </div>
      </div>

      <section className="process">
        <div className="process_step">
          <h3 className="process_step_title"><span className="process_step_number">1</span>Research</h3>
          <p>We interviewed students and education specialists.</p>
        </div>
        <div className="process_step">
          <h3 className="process_step_title"><span className="process_step_number">2</span>Co-creation</h3>
          <p>A great team work providing solutions ideias.</p>
        </div>
        <div className="process_step">
          <h3 className="process_step_title"><span className="process_step_number">3</span>Designing</h3>
          <p>User flow and prototype design in Figma.</p>
        </div>
        <div className="process_step">
          <h3 className="process_step_title"><span className="process_step_number">4</span>Testing</h3>
          <p>Prototype tested with five students.</p>
        </div>
      </section>

      <section className="cover-img proto">
        <video src={require('../../img/prototype-enfase.mp4')} width="750" height="500" autoPlay loop muted/>
      </section>

      <div className="container">
        <div className="col1">
        </div>

        <div className="col2">
        <p className="casepage-paragraphy">a brazilian designer, who belives that design is for everyone, but not in the cliches ways. a brazilian designer, who belives that design is for everyone, but not in the cliches ways. a brazilian designer, who belives that design is for everyone, but not in the cliches ways.</p>
          <p className="casepage-paragraphy">a brazilian designer, who belives that design is for everyone, but not in the cliches ways. a brazilian designer, who belives that design is for everyone, but not in the cliches ways. a brazilian designer, who belives that design is for everyone, but not in the cliches ways.</p>
          <h2 class="casepage-deliverables">
            <span className="casepage-deliverables_hat">Deliverable</span>
            <span className="casepage-deliverables_text">Entire new flow experience</span>
          </h2>
          <h2 class="casepage-deliverables">
            <span className="casepage-deliverables_hat">Deliverable</span>
            <span className="casepage-deliverables_text">Entire new flow experience</span>
          </h2>
          <h2 class="casepage-deliverables">
            <span className="casepage-deliverables_hat">Deliverable</span>
            <span className="casepage-deliverables_text">Entire new flow experience</span>
          </h2>
        </div>
      </div>

      <div className="case-gallery">
        <div class="case-gallery_col1">
          <figure className="case-gallery_photo">
            <img src={require('../../img/enfase-mockup.jpg')} />
          </figure>
        </div>
        <div class="case-gallery_col2">
        <figure className="case-gallery_photo">
            <img src={require('../../img/enfase-1.jpg')} />
          </figure>
          <figure className="case-gallery_photo">
            <img src={require('../../img/enfase-2.jpg')} />
          </figure>
        </div>
      </div> 

    <section className="colaborators container">
        <div class="col1"><strong>My role </strong><br></br>Interface Designer</div>
        <div class="col2">
            <ul>
                <strong>Also in this project</strong>
                <li>Solange Rezende</li>
                <li>Anandsing Dwarkasing</li>
                <li>Gabriel Gian</li>
                <li>Erik Navarro</li>
                <li>Paulo Lepore</li>
                <li>João Mendes</li>
            </ul>
        </div>
      </section>

      <section className="articles">
      <a href="./cerveja-me" className="article">
          <figure>
            <img src={require('../../img/cerveja-me-mockup.jpg')} />
          </figure>
          <div className="article-title">
            <span className="article-title_hat">Interface, Interaction</span>
            <span className="article-title_postname">Delivery App<br></br>Interface</span>
          </div>
          <span className="link-arrow">
            <svg width="231" height="8" viewBox="0 0 231 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M227.172 0.464318L230.354 3.6463C230.549 3.84156 230.549 4.15814 230.354 4.3534L227.172 7.53539C226.976 7.73065 226.66 7.73065 226.464 7.53539C226.269 7.34012 226.269 7.02354 226.464 6.82828L228.793 4.49985H0V3.49985H228.793L226.464 1.17142C226.269 0.976162 226.269 0.65958 226.464 0.464318C226.66 0.269056 226.976 0.269056 227.172 0.464318Z"  />
            </svg>
          </span>
        </a>
      </section>

      <footer id="contact">
        <span className="footer-heading">don't be shy, contact me.</span>
        <span className="footer-links">
          <a target="_blank" className="link" href="mailto:glauberlaender@gmail.com">Mail</a>
          <a target="_blank" className="link" href="tel:+5511941870613">Phone</a>
          <a target="_blank" className="link" href="http://linkedin.com/in/glauberlaender">Linkedin</a>
          <a target="_blank" className="link" href="http://twitter.com/glauberlaender">Twitter</a>
          <a target="_blank" className="link" href="http://instagram.com/glauberlaender">Instagram</a>
          <a target="_blank" className="link" href="http://savee.it/g_laender">Savee</a>
          <a target="_blank" className="link" href="http://pinterest.com/glauberlaender">Pinterest</a>
        </span>

        <p>by the way, this website was <a target="_blank" className="link" href="https://github.com/glauberlaender/laender.me/tree/master/laender-me">coded by me</a>.</p>
      </footer>

    </div>

  </div>;
};

export default EnfaseInterface;

import React from "react";
import '../../assets/styles/index.scss';
import '../../assets/styles/themes/claro.scss';

const RedesignNow = () => {
  return <div>
    
    <div className="App claro">
    <header>
        <span>
        <a className="btn-back" href="./"><svg width="95" height="8" viewBox="0 0 95 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM95 3.5H1V4.5H95V3.5Z"  />
          </svg>
          </a>
          <strong>Redesign Now TV App</strong>
        </span>
        {/* <ul className="menu-languages">
          <li className="active">EN</li>
          <li>ES</li>
          <li>PT</li>
        </ul> */}
        <span>
        <a className="link" href="#contact">Contact</a>
        </span>
      </header>

      <section className="cover">
        <span className="cover-name">Redesign Now App</span>
      </section>

      <section className="cover-img">
        <img src={require('../../img/now-full.jpg')} />
      </section>

      <div className="container">
        <div className="col1">
          <h1 class="casepage-title">
            <span className="casepage-title_hat">Design Sprint <span className="dash"></span> Sprint Leader</span>
            <span className="casepage-title_postname">Redesign <br/> Now TV App</span>
          </h1>
        </div>

        <div className="col2 casepage-client">
          <span className="casepage-partnership">by <a target="_blank" href="http://meiuca.design">Meiuca</a> for <svg width="42" height="15" viewBox="0 0 42 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M32.9028 12.3835C32.4813 12.8205 31.9645 13.0273 31.3518 13.0273C30.7576 13.0273 30.2411 12.8205 29.8185 12.3835C29.3973 11.9476 29.167 11.4348 29.167 10.8478C29.167 10.2219 29.3973 9.71011 29.8185 9.29306C30.2411 8.85562 30.7576 8.64792 31.3518 8.66676C31.9645 8.64792 32.4813 8.85562 32.9213 9.29306C33.3233 9.71011 33.5345 10.2219 33.554 10.8478C33.5345 11.4348 33.3233 11.9476 32.9028 12.3835ZM34.3005 7.90795C33.4768 7.09317 32.4813 6.6945 31.3518 6.6945C30.222 6.6945 29.2438 7.09317 28.4408 7.90795C27.6169 8.72348 27.2143 9.71011 27.2143 10.8478C27.2143 11.9857 27.6169 12.9719 28.4408 13.7685C29.2438 14.5838 30.222 15 31.3518 15C32.4813 15 33.4768 14.5838 34.3005 13.7685C35.1052 12.9719 35.5074 11.9857 35.5074 10.8478C35.5074 9.71011 35.1052 8.72348 34.3005 7.90795Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.13278 4.66474C3.69734 4.64666 2.50915 5.13928 1.51363 6.16387C0.497127 7.16826 0 8.36297 0 9.78567C0 11.1884 0.497127 12.4217 1.51363 13.4265C2.50915 14.4128 3.69734 14.9063 5.13278 14.9063C6.28196 14.9063 7.33525 14.5649 8.25405 13.8633C9.15519 13.143 9.74825 12.251 10.0547 11.1507H7.96726C7.69884 11.6823 7.31573 12.1184 6.83746 12.4584C6.301 12.7824 5.74532 12.9522 5.13278 12.9522C4.25226 12.9522 3.50525 12.6298 2.89226 12.0236C2.26073 11.3976 1.95385 10.6582 1.97249 9.78567C1.95385 8.91361 2.26073 8.17373 2.89226 7.54725C3.50525 6.94017 4.25226 6.6194 5.13278 6.6194C5.74532 6.6194 6.301 6.7898 6.83746 7.13105C7.31573 7.45369 7.69884 7.90823 7.96726 8.43895H10.0547C9.74825 7.33973 9.15519 6.42801 8.25405 5.72808C7.33525 5.00765 6.28196 4.64666 5.13278 4.66474Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.6154 11.1323L18.998 10.9233V11.7201C18.998 12.403 18.807 12.8774 18.4426 13.143C18.0598 13.4076 17.6578 13.5404 17.199 13.5404C16.9115 13.5404 16.6809 13.4638 16.4892 13.3126C16.2978 13.143 16.2024 12.8955 16.2024 12.5355C16.2024 12.1363 16.3558 11.8523 16.6809 11.6437C16.8725 11.5303 17.199 11.435 17.6196 11.3792L18.6154 11.1323ZM17.7725 6.67701C16.4321 6.65699 15.4939 7.01624 14.9574 7.71899C14.6126 8.15488 14.4409 8.72348 14.3825 9.44418H16.3755C16.4135 9.10302 16.5281 8.87559 16.6622 8.72348C16.8725 8.45779 17.2176 8.32443 17.7154 8.34412C18.1366 8.32443 18.4626 8.40107 18.6918 8.53415C18.9214 8.64791 19.0181 8.87559 19.0376 9.19776C19.0376 9.46363 18.8842 9.67288 18.5771 9.80479L17.046 10.0507C16.3357 10.1463 15.7622 10.3164 15.3213 10.5629C14.5747 10.9996 14.2113 11.7005 14.2289 12.7064C14.2113 13.4076 14.4408 13.9769 14.899 14.394C15.3029 14.7363 15.8578 14.9252 16.5281 14.9441C17.6196 14.9252 18.4821 14.6604 19.0938 14.1283V14.9441H21.0866V9.14099C21.0866 8.19294 20.7415 7.5284 20.0896 7.18771C19.4001 6.82682 18.6345 6.65699 17.7725 6.67701Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.0702 14.9441H13.0612V4.66474H11.0702V14.9441Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.7401 6.96017C25.2039 7.16877 24.7256 7.52839 24.3228 8.07913V7.07385L22.4262 7.05435V14.9441H24.3997V11.1691C24.3997 10.5257 24.4756 10.0702 24.6479 9.78566C24.7832 9.48247 25.0318 9.23554 25.3374 9.02764C25.6827 8.79908 26.0463 8.68616 26.4297 8.68616L27.0423 8.7231L27.0231 6.71324L26.5061 6.75125L25.7401 6.96017Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M35.8518 6.99787L40.6604 2.23842L39.3944 0.986123L34.5878 5.74673L35.8518 6.99787Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.7008 5.1016H32.4813V0H30.7008V5.1016Z" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.7329 10.7347H42V8.96997H36.7329V10.7347Z" />
</svg> | São Paulo, Brazil</span>
        </div>
      </div>

      <section className="process">
        <div className="process_step">
          <h3 className="process_step_title"><span className="process_step_number">1</span>Insights</h3>
          <p>Research insights and data analysis.</p>
        </div>
        <div className="process_step">
          <h3 className="process_step_title"><span className="process_step_number">2</span>Ideation</h3>
          <p>Multidisciplinary team co-creating solutions.</p>
        </div>
        <div className="process_step">
          <h3 className="process_step_title"><span className="process_step_number">3</span>Testing</h3>
          <p>Usability testing with real users at fisical stores.</p>
        </div>
        <div className="process_step">
          <h3 className="process_step_title"><span className="process_step_number">4</span>Refining</h3>
          <p>Prototype adjustments based in the test's feedback.</p>
        </div>
      </section>

      <div className="container">
        <div className="col1">
        </div>

        <div className="col2">
          <p className="casepage-paragraphy">Meiuca and me was invited to redesign the Now TV App for Clasil Brasil group. A product with many users and very important for the company.</p>
          <p className="casepage-paragraphy">This project was huge and had a lot of people involved. The project stared in partinership with a research consulting company, Zoly, to understand some user issues and usability problems in the current version. The DATA department was ivolved to bring us some usage data, user behavior and patterns.</p>
          <p className="casepage-paragraphy">After undestanding the issues we co-created solutions with some Executives, Designers and Developers, prototyped and tested with real users at Claro's fisical stores.</p>
          <h2 class="casepage-deliverables">
            <span className="casepage-deliverables_hat">Method</span>
            <span className="casepage-deliverables_text">Five days <br></br>Design Sprint</span>
          </h2>
          <h2 class="casepage-deliverables">
            <span className="casepage-deliverables_hat">Deliverable</span>
            <span className="casepage-deliverables_text">New interface <br></br>and user flow</span>
          </h2>
          <h2 class="casepage-deliverables">
            <span className="casepage-deliverables_hat">Testing</span>
            <span className="casepage-deliverables_text">Usability testing <br></br>at fisical stores</span>
          </h2>
        </div>
      </div>

      <div className="case-gallery">
        <div class="case-gallery_col1">
          <figure className="case-gallery_photo">
            <img src={require('../../img/now-mockup.jpg')} />
          </figure>
        </div>
        <div class="case-gallery_col2">
        <figure className="case-gallery_photo">
            <img src={require('../../img/now-3.png')} />
          </figure>
          <figure className="case-gallery_photo">
            <img src={require('../../img/now-4.png')} />
          </figure>
        </div>
      </div> 

      <section className="colaborators container">
        <div class="col1"><strong>My role</strong><br></br>Sprint Leader, UX Designer</div>
        <div class="col2">
            <ul>
                <strong>Also in this project</strong>
                <li>Marcelle Aleixo</li>
                <li>Bianca Brancaleone</li>
                <li>Tereza Alux</li>
                <li>Lais Mastelari</li>
                <li>Daniel Ito</li>
                <li>Willian Schilling</li>
            </ul>

            <ul>
                <strong>Pics</strong>
                <li>Matheus Ferreira</li>
            </ul>
        </div>
      </section>

      <section className="articles">
      <a href="./enfase-interface" className="article">
          <figure>
            <img src={require('../../img/enfase-mockup.jpg')} />
          </figure>
          <div className="article-title">
            <span className="article-title_hat">Interface</span>
            <span className="article-title_postname">Study Planner Dashboard</span>
          </div>
          <span className="link-arrow">
            <svg width="231" height="8" viewBox="0 0 231 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M227.172 0.464318L230.354 3.6463C230.549 3.84156 230.549 4.15814 230.354 4.3534L227.172 7.53539C226.976 7.73065 226.66 7.73065 226.464 7.53539C226.269 7.34012 226.269 7.02354 226.464 6.82828L228.793 4.49985H0V3.49985H228.793L226.464 1.17142C226.269 0.976162 226.269 0.65958 226.464 0.464318C226.66 0.269056 226.976 0.269056 227.172 0.464318Z"  />
            </svg>
          </span>
        </a>
      </section>

      <footer id="contact">
        <span className="footer-heading">don't be shy, contact me.</span>
        <span className="footer-links">
          <a target="_blank" className="link" href="mailto:glauberlaender@gmail.com">Mail</a>
          <a target="_blank" className="link" href="tel:+5511941870613">Phone</a>
          <a target="_blank" className="link" href="http://linkedin.com/in/glauberlaender">Linkedin</a>
          <a target="_blank" className="link" href="http://twitter.com/glauberlaender">Twitter</a>
          <a target="_blank" className="link" href="http://instagram.com/glauberlaender">Instagram</a>
          <a target="_blank" className="link" href="http://savee.it/g_laender">Savee</a>
          <a target="_blank" className="link" href="http://pinterest.com/glauberlaender">Pinterest</a>
        </span>

        <p>by the way, this website was <a className="link" href="">coded by me</a>.</p>
      </footer>

    </div>

  </div>;
};

export default RedesignNow;

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import CervejaMe from "./pages/cerveja-me";
import Home from "./pages/Home";
import RedesignNow from "./pages/redesign-now";
import WelcomeToFavela from "./pages/welcome-to-favela";
import SomaDS from "./pages/soma-design-system";
import EnfaseInterface from "./pages/enfase-interface";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact={true} component={Home} />
      <Route path="/redesign-now" exact={true} component={RedesignNow} />
      <Route path="/welcome-to-favela" exact={true} component={WelcomeToFavela} />
      <Route path="/cerveja-me" exact={true} component={CervejaMe} />
      <Route path="/soma-design-system" exact={true} component={SomaDS} />
      <Route path="/enfase-interface" exact={true} component={EnfaseInterface} />
      {/* <Route path="/case" component={case} /> */}
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
